import axios from "axios";
import { getHeaders } from "../../Utils/axiosConfig";

export const getClosures = async (email: string) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/apimanager/cierre/",
    getHeaders(email)
  );
};

export const getHistory = async (email: string) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/apimanager/monthHistorical/",
    getHeaders(email)
  );
};

export const getMonth = async () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/apimanager/monthModify/",
    getHeaders()
  );
};

export const getTypes = async (email: string) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/apimanager/tipos/",
    getHeaders(email)
  );
};

export const getPrinexDate = async (horas: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + `/apimanager/horasCortes/${horas}`,
    getHeaders()
  );
};

export const addClosure = async (data: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/apimanager/ejecucion/",
    data,
    getHeaders()
  );
};
