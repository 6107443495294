export const getHeaders = (email: string | null = null) => {
  const headers: any = {
    Authorization: `Bearer ${sessionStorage.getItem("aedas_user_token")}`,
  };

  if (email) {
    headers["Email"] = email;
  }

  return {
    headers,
  };
};
